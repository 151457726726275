import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

import Home from './Home';
import NoMAtch from './pages/404';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-129585887-2');
ReactGA.pageview(window.location.pathname + window.location.search);

console.log = console.warn = console.error = () => {};

class Root extends Component {

    //window.location.pathname.replace(/(\/[^/]+)$/, "")
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route component={Home} />
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(

    <Root style={{ overflowX: 'hidden' }} />


    , document.getElementById('root'));

serviceWorker.register();