import React, { Component } from 'react';
import { animateScroll as scroll, Link } from 'react-scroll';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    handleScroll() {
        if (this.mount) {
            this.setState({ scroll: window.scrollY });
        }
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    notifEvent() {
        window.gtag("event", "consulta");
    }

    render() {
        return (
            <>
                    <div className="footer-area dark-bg footerDiv">
                        <div className="container" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-3 no-gutters  todofooter borderFooter footerPuertoMobile notShowOnMobile ajustefooooter">
                                    <a href="/" className='notShowOnMobile'><img src={'./assets/img/FOOTER_puertos.png'} width='100px' /></a>
                                    <a href="/" className='notShowOnDESKTOP'><img src={'./assets/img/FOOTER_p.png'} width='40px' /></a>
                                    <p className="footerdesc">Puertos del Lago</p>
                                    <p className="footerdesc">KM 45. Panamericana Ramal Escobar</p>
                                </div>
                                <div className="col-12 col-md-3 no-gutters  todofooter borderFooter footerPuertoMobile notShowOnDESKTOP">
                                    <div className="row no-gutters">
                                        <div className="col-4">
                                            <a href="/" className='notShowOnDESKTOP'><img src={'./assets/img/FOOTER_p.png'} className='imglogofotooter' width='40px' /></a>
                                        </div>
                                        <div className="col-8">
                                            <p className="footerdescPuertos">Puertos del Lago</p>
                                            <p className="footerdescPuertos">KM 45. Panamericana Ramal Escobar</p>
                                        </div>
                                    </div>
                                </div>
                                <ol className="col-12 col-md-3 todofooter borderFooter items">
                                    <li className="imgFooter consultatioLogo">
                                        <a href="https://www.consultatio.com.ar" target="_blank"><img src={'./assets/img/consultatio.svg'} width='100%' className="imglogofotooter22" /></a>
                                    </li>
                                    <li>
                                        <a href={'https://api.whatsapp.com/send?phone=' + this.props.numContacto + '&source=&data=&app_absent='} onClick={() => this.notifEvent()} target="blank"><img src={'./assets/img/what.png'} width='32px' /></a>
                                        <a href="https://www.facebook.com/puertosescobar/" target="blank"><img src={'./assets/img/fb.png'} width='32px' /></a>
                                        <a href="https://www.instagram.com/puertos_escobar/" target="blank"><img src={'./assets/img/ig.png'} width='32px' /></a>
                                    </li>
                                </ol>
                                <div className="col-12 col-md-3 legaly todofooter footerTextoDerecha">
                                    <div className="row no-gutters">
                                        <div className="col-4">
                                            <p className="footerdesc">
                                                <Link onClick={() => this.props.clickCiudad()}>Ciudad</Link>
                                            </p>
                                            <p className="footerdesc">
                                                <Link onClick={() => this.props.clickMaster()}>Masterplan</Link>
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="footerdesc">
                                                <Link onClick={() => this.props.clickUbicacion()}>Ubicación</Link>
                                            </p>
                                            <p className="footerdesc">
                                                <Link onClick={() => this.props.clickContacto()}>Contacto</Link>
                                            </p>
                                        </div>
                                        <div className="col-4">
                                            <p className="footerdesc"><a href="https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Legales.pdf" target="_blank">Legal</a></p>
                                            <p className="footerdesc" target="_blank"><a href="http://zerobug.com.ar/">Site Credits</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {
                    this.props.numContacto ?
                        <a href={'https://api.whatsapp.com/send?phone=' + this.props.numContacto + '&source=&data=&app_absent='} className="whatsapp" target="blank" onClick={() => this.notifEvent()}>
                            <img src='./assets/img/whatsapp-512.png' className="whatsappimg" />
                        </a>
                        :
                        null
                }
            </>

        )
    }
}


export default Footer;