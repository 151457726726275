import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

class ResidenciasDelLago extends Component {
    render() {

        const arrowStyles: CSSProperties = {
            position: 'absolute',
            zIndex: 2,
            top: 'calc(50% - 15px)',

            cursor: 'pointer',

            background: 'none',
            border: 'none',
        };

        const indicatorStyles: CSSProperties = {
            background: '#fff',
            width: 10,
            height: 10,
            display: 'inline-block',
            margin: '0 8px',
            borderRadius: '10px'
        };

        const customRenderThumb = (children) => null;

        return (
            <div className="ciudadVerde">
                {/*====================  project slider area ====================*/}
                <div className="project-slider-area sectionTitulosEspaciadoBottom">
                    <div className="container">
                        <div className="row" >
                            <div className="col-lg-12 sectionTitulosSinEspaciado">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title">Residencias del Lago</h2>
                                   
                                        <p className="subtituloSeccion">Viví en el corazón de la ciudad, en contacto con el verde y el agua.</p>
                                        <p className="subtituloSeccion">Últimas unidades disponibles con vistas únicas al lago.</p>
                                   
                                    {/* <MobileView>
                                        <p className="subtituloSeccion">Viví en el corazón de la ciudad, en contacto con el verde y el agua. Últimas unidades disponibles con vistas únicas al lago.</p>
                                    </MobileView> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 paddsCarrousel">
                                {/* <BrowserView> */}
                                    <Carousel
                                        autoPlay={true}
                                        showArrows={isMobile ? false : true}
                                        infiniteLoop={true}
                                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                                            ''
                                        }}
                                        renderThumbs={customRenderThumb}
                                        statusFormatter={(current, total) => ''}
                                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                            hasPrev && (
                                                <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                                                    <img src={'./assets/img/flecha_atras.svg'} />
                                                </button>
                                            )
                                        }
                                        renderArrowNext={(onClickHandler, hasNext, label) =>
                                            hasNext && (
                                                <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
                                                    <img src={'./assets/img/flecha_siguiente.svg'} />
                                                </button>
                                            )
                                        }
                                    >
                                        <div>
                                            <img src="./assets/img/residencias/residencias_del_lago_1.jpg" />

                                        </div>
                                        <div>
                                            <img src="./assets/img/residencias/residencias_del_lago_2.jpg" />

                                        </div>
                                        <div>
                                            <img src="./assets/img/residencias/residencias_del_lago_3.jpg" />

                                        </div>
                                        <div>
                                            <img src="./assets/img/residencias/residencias_del_lago_4.jpg" />

                                        </div>
                                        <div>
                                            <img src="./assets/img/residencias/residencias_del_lago_5.jpg" />

                                        </div>
                                    </Carousel>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default ResidenciasDelLago;