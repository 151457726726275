import React, { Component } from 'react';


class ContactoBanner extends Component {


    constructor() {
        super()

        this.state = {
            values: {
                name: null,
                surname: null,
                phone: null,
                email: null,
                entrepreneurshipid: 1,
                producttypeid: 0,
                message: null,
                productid: null
            },
            isSubmitting: false,
            isError: false,
            message: null
        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    handleInputChange = e => {
        this.setState({
            values: { ...this.state.values, [e.target.name]: e.target.value }
        });
    }


    submitForm = async e => {

        e.preventDefault();

        this.setState({ isSubmitting: true });

        const res = await fetch(this.props.apiMailing, {
            method: "POST",
            body: JSON.stringify(this.state.values),
            headers: {
                "Content-Type": "application/json"
            }
        });

    
        //registro de evento en google
        window.gtag("event", "formulario");

        this.setState({ isSubmitting: false });
        const data = await res.json();

        if (data.success == true) {
            this.setState({ message: data.result })
            setTimeout(() =>
                this.setState({
                    isError: false,
                    message: null,
                    values: {
                        name: '',
                        surname: '',
                        phone: '',
                        email: '',
                        entrepreneurshipid: 1,
                        producttypeid: 0,
                        message: '',
                    },
                }),
                2500
            );
        } else {
            this.setState({ message: data.message, isError: true });
            setTimeout(() =>
                this.setState({
                    isError: false,
                    message: null,
                }),
                2500
            );
        }

    };

    render() {
        return (
            <div style={{ backgroundColor: '#012338' }}>
                <div className="service-grid-slider-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 colBanner2">
                                <h1 class='txtWhite titulocontacto'>Contactate con nosotros</h1>
                                <h5 className="footerContacto">info@puertos.com.ar | 0800-888-puertos</h5>
                            </div>
                            <div className="col-lg-6 formcontainer">
                                <div className="contact-form">
                                    <form id="contact-form" onSubmit={this.submitForm} autoComplete="off">
                                        <div className="row">
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    placeholder="*Nombre"
                                                    value={this.state.values.name}
                                                    onChange={this.handleInputChange}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="surname"
                                                    id="surname"
                                                    autoComplete="off"
                                                    type="text"
                                                    placeholder="*Apellido"
                                                    value={this.state.values.surname}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="phone"
                                                    id="phone"
                                                    autoComplete={false}
                                                    type="text"
                                                    placeholder="*Teléfono"
                                                    value={this.state.values.phone}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="email"
                                                    id="email"
                                                    autoComplete={false}
                                                    type="text"
                                                    placeholder="*Correo eléctronico"
                                                    value={this.state.values.email}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 selectdiv">
                                                <select name="entrepreneurshipid"
                                                    style={{borderRadius: "3px"}}
                                                    value={this.state.values.entrepreneurshipid}
                                                    onChange={this.handleInputChange}
                                                    autoComplete={false}
                                                    className="selectcontact"
                                                >
                                                    {
                                                        this.props.dataLotes ?
                                                            this.props.dataLotes.map((val, i) => {
                                                                return (
                                                                    <option key={i} value={val.id}>{val.name}</option>
                                                                )
                                                            })
                                                            :
                                                            null
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-6 col-12 selectdiv">
                                                <select name="producttypeid"
                                                    style={{borderRadius: "3px"}}
                                                    value={this.state.values.producttypeid}
                                                    onChange={this.handleInputChange}
                                                    autoComplete={false}
                                                    className="selectcontact" placeholder="Tipo de producto" >
                                                    <option>Tipo de producto</option>
                                                    {this.props.productos ?
                                                        this.props.productos.map((val, i) => {
                                                            return (
                                                                <option key={i} value={val.id}>{val.name}</option>
                                                            )
                                                        })
                                                        :
                                                        null
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <textarea name="message"
                                                    id="message"
                                                    type="text"
                                                    placeholder="*Mensaje"
                                                    autoComplete={false}
                                                    value={this.state.values.message}
                                                    onChange={this.handleInputChange} defaultValue={""} /></div>
                                            {
                                                this.state.isError ?
                                                    <p className="msjFooterE">
                                                        {this.state.message}
                                                    </p>
                                                    :
                                                    this.state.message ?
                                                        <p className="msjFooter">
                                                            {this.state.message}
                                                        </p>
                                                        :
                                                        null
                                            }
                                            <div className="col-12"><button className='btnenviarContacot' type={'submit'}>
                                                {this.state.isSubmitting ?
                                                    'Enviando ...' : 'Enviar'}
                                            </button></div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactoBanner;