import React, { Component } from 'react';
import ReactPlayer from 'react-player/youtube'

class SliderVideo extends Component {

    constructor(props) {
        super(props);
        this.state = { ancho: 0, alto: 0, alturaVideo: 0 };

        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        const el = document.querySelector('#sliderVideo');
        this.setState({ ancho: el.offsetWidth, alto: el.offsetHeight });

        if (this.state.ancho < 500) {
            var calculo = this.state.ancho * 360 / 640 - 58;
        } else {
            var calculo = this.state.ancho * 360 / 640;
        }
        this.setState({ alturaVideo: calculo });
    }

    render() {
        return (
            <div id="sliderVideo" className="container videoContenedor" ref={this.cajaVideo}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="videoPlayer video-container">

                            {/* 
                            <ReactPlayer
                                muted={true}
                                playing={true}
                                width={'100%'}
                                height={'100%'}
                                url='https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Video+Puertos.mp4'
                            /> 
                            */}
                            {/* 
                            <ReactPlayer muted={true}
                                playing={true}
                                width={this.state.ancho + 'px'}
                                height={this.state.alturaVideo + 'px'}
                                url='https://www.youtube.com/watch?v=nQIBA6xqfi8' 
                            /> 
                            
                            https://www.youtube.com/watch?v=
                            */}

                            <iframe
                                width="100%"
                                height={this.state.alturaVideo + 'px'}
                                src="https://www.youtube.com/embed/fDZ32mUYeUU?version=3&loop=1&playlist=fDZ32mUYeUU&autoplay=1&mute=1&enablejsapi=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                            </iframe>



                             </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SliderVideo;