import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import MapaLotes from './MapaLotes'
import ReactLoading from "react-loading";
import { FaCircle } from 'react-icons/fa';



const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

class TabLotes extends Component {

    constructor() {
        super()

        this.state = {
            loteID_Selected: 1,
            showBar: true,
            lotes: null,
            amenities: null,
            barrioSelect: 1,
        };

        this.toggleBar = this.toggleBar.bind(this);
        this.fetchBarrioLotes = this.fetchBarrioLotes.bind(this);

    }

    toggleBar() {
        this.setState({ showBar: !this.state.showBar });
        this.fetchBarrioLotes(this.state.loteID_Selected);
    }

    simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }

    setSelectedOption = (idTab) => {
        this.setState({ barrioSelect: idTab });
        var element = document.querySelector('li[tabindex="tabbbb' + idTab + '"]');
        this.simulateMouseClick(element);
    };

    fetchBarrioLotes(idLote) {

        this.setState({ lotes: null })
        this.setState({ loteID_Selected: idLote })

        fetch(this.props.api + '/api/product/landing/' + idLote)
            .then(response => response.json())
            .then(data => this.setState({ lotes: data }))
            .catch(data => console.log("error", data))
    }


    fetchAmenitiesLotes() {
        fetch(this.props.api + '/api/place/landing/PUERTOS')
            .then(response => response.json())
            .then(data => this.setState({ amenities: data }))
            .catch(data => console.log("error", data))
    }

    componentWillMount() {
        this.fetchAmenitiesLotes();
        this.fetchBarrioLotes(1);
    }

    render() {

        return (
            <div>
                <div className="service-tab-area sectionTitulosEspaciadoBottom">
                    <div className="container containerMobileLotesCiudad">
                        <div className="row">
                            <div className="col-lg-12 sectionTitulosEspaciado">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title ">Tu lugar en Puertos</h2>
                                    <p className="subtituloSeccion">Navegá nuestro masterplan y encontrá la propiedad que estás buscando.</p>
                                </div>
                            </div>
                            <div className="col-lg-12 selectdivTabs combomobilemapa notShowOnDESKTOP selectpadd">
                                <select
                                    name="barrioSeleccionado"
                                    className="selectcontact selectcontactBlanco"
                                    placeholder="Barrio"
                                    value={this.state.barrioSelect}
                                    onChange={e => this.setSelectedOption(e.target.value)}>

                                    {this.props.dataLotes ?
                                        this.props.dataLotes.map((val, i) => {
                                            return (
                                                <option key={i} value={val.id}>{val.name}</option>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </select>
                            </div>
                            <div className="col-lg-12" >
                                <div className="service-tab-wrapper">
                                    {this.props.dataLotes ?
                                        <div className='tabdelotes'>
                                            <Tabs>
                                                <div className="row no-gutterstabs">
                                                    <div className="col-md-3 miniCorrecion">
                                                        <TabList>
                                                            <div className="service-tab__link-wrapper">
                                                                {
                                                                    this.props.dataLotes ?
                                                                        this.props.dataLotes.map((val, i) => {
                                                                            return (
                                                                                <Tab key={i} tabIndex={'tabbbb' + val.id} id={'tab_id_barrio' + val.id} onClick={() => this.fetchBarrioLotes(val.id)}><span className="text">{val.name}</span></Tab>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </TabList>
                                                    </div>
                                                    <div className="col-md-9 tablotes tabsMobiledos">
                                                        {this.props.dataLotes ? this.props.dataLotes.map((val, i) => {
                                                            return (
                                                                <TabPanel key={i}>
                                                                    <div className="service-tab__single-content-wrapper">
                                                                        <div className="row entero">
                                                                            {
                                                                                this.state.showBar ?
                                                                                    <>
                                                                                        <div className="col-lg-4 order-2 order-md-1 sideLotes">
                                                                                            <h3 className="tituloTABBB notShowOnMobile">{val.name}</h3>
                                                                                            <button id="btn_mapa_container" onClick={this.toggleBar} className="btVolveer2 buttoCollappppseminusLOTES"><img src='./assets/img/masterplan_condenser.svg' /></button>
                                                                                            <p style={{ whiteSpace: "pre-line" }} className="descTabLotes">{val.description}</p>
                                                                                            {val.brochureurl ?
                                                                                                <div className="botonLotes">
                                                                                                    <a href={`${val.brochureurl}`} style={{ fontSize: '15px' }} target="_blank" className="ht-btn btnDescargar"><FaCircle width={'10px'} color={'#ff5730'} style={{ marginRight: '4px' }} />Descargar Ficha</a>
                                                                                                    {/*<a href={`${val.brochureurl}`} style={{ fontSize: '15px' }} target="_blank" className="ht-btn ht-btn--roundNewTabsDowload largoymedio"><FaCircle /> Descargar Ficha</a> */}
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-lg-8 order-1 order-md-2 contenedorMapa contenedorMapaLeaf">
                                                                                            {this.state.lotes ?
                                                                                                <MapaLotes
                                                                                                    imagenMapa={val.pictureurl}
                                                                                                    barrioNombre={val.name}

                                                                                                    txtMensaje={this.props.txtMensaje}
                                                                                                    apiMailing={this.props.apiMailing}

                                                                                                    barrios={this.props.dataLotes}
                                                                                                    barrioId={val.id}

                                                                                                    lotes={this.state.lotes}
                                                                                                    amenities={this.state.amenities}
                                                                                                    productos={this.props.productos}

                                                                                                    latCenter={val.latitude}
                                                                                                    lonCenter={val.longitude}
                                                                                                    zoom={val.mapzoomvalue}

                                                                                                    tipo_mapa={'corto'}
                                                                                                />
                                                                                                :
                                                                                                <div style={{ top: '46%', position: 'absolute', left: '43%' }}>
                                                                                                    <ReactLoading type={'balls'} color="#fff" />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="col-lg-1 sideLotesCerrado">
                                                                                            <button id="btn_mapa_container" onClick={this.toggleBar} className="btVolveer2 buttoCollappppseplusLOTES"><img src='./assets/img/masterplan_ampliar.svg' /></button>


                                                                                        </div>
                                                                                        <div className="col-lg-11 contenedorMapa contenedorMapaLeaf">
                                                                                            {this.state.lotes ?
                                                                                                <MapaLotes
                                                                                                    imagenMapa={val.pictureurl}
                                                                                                    barrioNombre={val.name}

                                                                                                    txtMensaje={this.props.txtMensaje}
                                                                                                    apiMailing={this.props.apiMailing}

                                                                                                    barrios={this.props.dataLotes}
                                                                                                    barrioId={val.id}

                                                                                                    lotes={this.state.lotes}
                                                                                                    amenities={this.state.amenities}
                                                                                                    productos={this.props.productos}

                                                                                                    latCenter={val.latitude}
                                                                                                    lonCenter={val.longitude}
                                                                                                    zoom={val.mapzoomvalue}

                                                                                                    tipo_mapa={'largo'}
                                                                                                />
                                                                                                :
                                                                                                <div style={{ top: '46%', position: 'absolute', left: '43%' }}>
                                                                                                    <ReactLoading type={'balls'} color="#fff" />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </TabPanel>
                                                            )
                                                        })
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </Tabs>
                                        </div>
                                        :

                                        <ReactLoading type={'balls'} color="#000" />

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabLotes;