import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class ImgBanner3 extends Component {
    render() {
        const params = {
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPagenation: () => (
                <div className="swiper-pagination"></div>
            ),
        }


        return (
            <div className='notShowOnMobile'>
                <div className={'testimonial-slider-area testimonial-slider-area-bglast'} style={{ height: '552px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="testimonial-slider">
                                    <div className="testimonial-slider__container-area">
                                        <div className="swiper-pagination"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ImgBanner3;