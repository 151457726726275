import React, { Component } from 'react';


class PuertosUbicacion extends Component {
    render() {
        return (
            <div className="ciudadVerde">
                {/*====================  project slider area ====================*/}
                <div className="project-slider-area grey-bgOnlyDEsktop sectionTitulosEspaciadoBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 sectionTitulosEspaciado paddingCenter">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title">Cambiá gris por verde</h2>
                                    <p className="subtituloSeccion">A solo 40 minutos de CABA, la oportunidad perfecta para un cambio de aire.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 order-2 order-md-1 colMapa flodMapaDesc">
                                <h1 style={{ fontSize: '25px', color: '#001B2C' }}>Panamericana KM 45.</h1>
                                <h1 style={{ fontSize: '25px', marginBottom: '35px', lineHeight: "0.5", color: '#001B2C' }}>Ramal Escobar</h1>
                                <p className="txtMapa">Puertos cuenta con un excelente acceso directo de 4km asfaltado, iluminado y de dos vías por mano desde Panamericana. Una ciudad en medio del verde, con un plan integral sólido, y una propuesta urbanística que combina de una manera única el valor artístico, paisajístico y ambiental.</p>
                                <h5 className="footerContacto notShowOnMobile" style={{ color: "#1D1D25" ,fontSize: '15px' }}>Buenos Aires | Argentina</h5>
                            </div>
                            <div className="col-md-6  order-1 order-md-2  col2Mapa flodMapaDesc">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3293.875003337807!2d-58.73779978477698!3d-34.35365918052491!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bb5ffddf2fcf41%3A0x44251fa3790e7811!2sPuertos%20%2F%20Lago%20Escobar!5e0!3m2!1sen!2sar!4v1593735745682!5m2!1sen!2sar" frameborder="0" style={{ border: '0px' }} allowfullscreen="false" className='map'></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default PuertosUbicacion;