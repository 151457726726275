import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import MapaLotes from './MapaLotes'

import ReactLoading from "react-loading";
import { FaCircle } from 'react-icons/fa';
import { FaCaretDown } from 'react-icons/fa';


 


const mouseClickEvents = ['mousedown', 'click', 'mouseup'];


class TabCiudad extends Component {

    constructor() {
        super()

        this.state = {

            showBar: true,
            lotes: null,
            idSeleccionado: 1,

            data: [
                { value: 1, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Vida+n%C3%A1utica.pdf', label: 'Vida Naútica', description: 'Un imponente entorno acuático con un gran lago central de más de 400 hectáreas. Lugar perfecto para elegir el más amplio abanico de actividades recreativas y deportivas, vivir la emoción del wakepark más extenso del país o elegir una orilla para contemplar el paisaje.', img: 'Nautica.gif', video: 'https://' },
                { value: 2, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Club+Puertos_.pdf', label: 'Club Deportivo', description: 'En Puertos el deporte y la vida al aire libre son fundamentales: tenis, fútbol, hockey, actividades náuticas, escenarios perfectos para el running y un gimnasio de última generación. Con propuestas siempre renovadas e infraestructura y actividades para todos, el Club es un gran punto de encuentro para los vecinos.', img: 'Club.gif', video: 'https://' },
                { value: 3, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/La+reserva.pdf', label: 'Reserva Natural', description: 'Un corredor biológico sobre la ribera, donde se preservan las especies autóctonas del lugar. 60 hectáreas de superficie libre, bordeando el Río Luján, que permiten la circulación de fauna entre los diferentes hábitats.', img: 'Reserva.gif', video: 'https://' },
                { value: 4, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Educaci%C3%B3n.pdf', label: 'Educación', description: 'Colegios que vinculan el aprendizaje con el cuidado del medio ambiente, el deporte, la náutica y la sustentabilidad. Dos instituciones de trayectoria tienen sede en Puertos: St. John’s y Northfield ofrecen los tres niveles educativos a residentes y niños de comunidades vecinas.', img: 'Educacion.gif', video: 'https://' },
                { value: 5, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Gastronomia.pdf', label: 'Gastronomía', description: 'Una propuesta ubicada en la Plaza Central con una increíble vista al Lago. Cuenta con una carta pensada para toda la familia, con alternativas que combinan nuevos aromas y sabores.', img: 'Gastronomia.gif', video: 'https://' },
                { value: 6, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Centro+de+destino.pdf', label: 'Centro de Destino', description: 'Es el centro neurálgico de la ciudad, situado en el corazón de Puertos, junto al Lago. Con propuestas recreativas y la más completa oferta de servicios: atención médica, oficinas, consultorios, peluquería, cajero, librería, entre otros.', img: 'destino.gif', video: 'https://' },
                { value: 7, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Arte.pdf', label: 'Arte', description: 'La experiencia estética como valor cultural. Un programa integral de arte a cielo abierto, en el que la ciudad y el paisaje son concebidas como piezas de arte en sí mismas. Con instalaciones y obras en espacios cotidianos, promueve un espíritu lúdico en grandes y chicos.', img: 'arte.gif', video: 'https://' },
                { value: 8, brochureurl: 'https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Amenities/Sustentabilidad.pdf', label: 'Sustentabilidad', description: 'Proyecto paisajístico con preponderancia de especies autóctonas. Redes de agua para riego y agua potable. Programa de calificación de construcción sustentable. Centro de sustentabilidad. Separación de los residuos en origen. Producción de chips y compost. Movilidad saludable y sustentable.', img: 'sustentabilidad.gif', video: 'https://' },
            ],
        };

        this.toggleBar = this.toggleBar.bind(this);
        this.fetchBarrioLotes = this.fetchBarrioLotes.bind(this);

    }

    toggleBar() {
        this.setState({
            showBar: !this.state.showBar
        });
    }

    fetchBarrioLotes(idLote) {
        fetch(this.props.api + '/api/product/landing/' + idLote)
            .then(response => response.json())
            .then(data => this.setState({ lotes: data }));
    }


    simulateMouseClick(element) {
        mouseClickEvents.forEach(mouseEventType =>
            element.dispatchEvent(
                new MouseEvent(mouseEventType, {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                    buttons: 1
                })
            )
        );
    }

    setSelectedOption = (idTab) => {
        this.setState({ idSeleccionado: idTab });
        var element = document.querySelector('li[tabindex="tabbbb-' + idTab + '"]');
        this.simulateMouseClick(element);
    };

    render() {


        const customStyles = {
            control: (base, state) => ({
                ...base,
                height: '60px',
                'min-height': '60px',

                'background-color': '#ff5631',
                'color': 'white',
                'border-radius': '0px',
                'border': '0px',

            }),
        };

        const CaretDownIcon = () => {
            return <FaCaretDown/>;
        };

     
        return (
            <div>
                <div className="service-tab-area sectionTitulosEspaciadoBottom">
                    <div className="container containerMobileLotesCiudad">
                        <div className="row">
                            <div className="col-lg-12 notShowOnMobile sectionTitulosEspaciado ajusteVideo">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title">Ciudad Natural</h2>
                                    <p className="subtituloSecciontwo">Más de 1.400 hectáreas: un gran lago central que conecta 20 barrios y 60 hectáreas de reserva</p>
                                </div>
                            </div>
                            <div className="col-lg-12 notShowOnDESKTOP sectionTitulosEspaciado">
                                <div className="section-title-area text-center">
                                    <h2 className="section-title">Ciudad Natural</h2>
                                    <p className="subtituloSeccion">Más de 1.400 hactáreas: un gran lago central que conecta 20 barrios y 60 hectáreas de reserva</p>
                                </div>
                            </div>
                            <div className="col-lg-12 selectdivTabs combomobile notShowOnDESKTOP selectpadd">
                            <select
                                    name="idSeleccionado"
                                    className="selectcontact selectcontactBlanco"
                                    placeholder="Ciudad"
                                    value={this.state.idSeleccionado}
                                    onChange={e => this.setSelectedOption(e.target.value)}
                                >
                                    {this.state.data ?
                                        this.state.data.map((val, i) => {
                                            return (
                                                <option key={i} value={val.value}>{val.label}</option>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </select>
                            {/*    <Select className="selectcontact selectcontactBlanco" options={this.state.data} />
                            */}
                            </div>
                            <div className="col-lg-12 tabsciudaddd">
                                <div className="service-tab-wrapper">
                                    {this.state.data ?
                                        <div className='tabdelotes'>
                                            <Tabs>
                                                <div className="row no-gutterstabs">
                                                    <div className="col-md-3 miniCorrecion">
                                                        <TabList>
                                                            <div className="service-tab__link-wrapper">
                                                                {
                                                                    this.state.data ?
                                                                        this.state.data.map((val, i) => {
                                                                            return (
                                                                                <Tab tabIndex={'tabbbb-' + val.value} key={i} ><span className="text">{val.label}</span></Tab>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </TabList>
                                                    </div>
                                                    <div className="col-md-9 tablotes tabsMobiledos">
                                                        {this.state.data ? this.state.data.map((val, i) => {
                                                            return (
                                                                <TabPanel key={i}>
                                                                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(./assets/gif/${val.img})` }}>


                                                                        <div className="row entero">
                                                                            {this.state.showBar ?
                                                                                <>
                                                                                    <div className="col-lg-4 order-2 order-md-1 sideLotes">
                                                                                        <button onClick={this.toggleBar} className="btVolveer2 buttoCollappppseminuscity"><img src='./assets/img/masterplan_condenser.svg' /></button>
                                                                                        <h3 className="tituloTABBB notShowOnMobile"> {val.label}</h3>
                                                                                        <p style={{ whiteSpace: "pre-line" }}>{val.description}</p>
                                                                                        {val.brochureurl ?
                                                                                            <div className="botonLotes">
                                                                                                <a href={`${val.brochureurl}`} style={{ fontSize: '15px' }} target="_blank" className="ht-btn btnDescargar"><FaCircle width={'10px'} color={'#ff5730'} style={{ marginRight: '4px' }} />Descargar Ficha</a>
                                                                                            </div>
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-lg-8 order-1 order-md-2 contenedorMapa" >
                                                                                        <img src={'./assets/gif/' + val.img} className="notShowOnDESKTOP" style={{ width: '100%' }} />
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div className="col-lg-1 order-2 order-md-1 sideLotesCerrado">
                                                                                        <button onClick={this.toggleBar} className="btVolveer2 buttoCollappppsepluscity"><img src='./assets/img/masterplan_ampliar.svg' /></button>
                                                                                    </div>
                                                                                    <div className="col-lg-11 order-1 order-md-2 contenedorMapa" >

                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </TabPanel>
                                                            )
                                                        })
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </Tabs>
                                        </div>
                                        :
                                        <ReactLoading type={'balls'} color="#000" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TabCiudad;