import React, { Component } from 'react'
import Modal from 'react-modal';
import ReactLoading from "react-loading";
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import $ from "jquery";

//const iconPath = process.env.PUBLIC_URL + './assets/img/mapa_marker.svg';
//const iconAmenitiePath = process.env.PUBLIC_URL + './assets/img/ameni_ico.svg';

mapboxgl.accessToken = 'pk.eyJ1IjoidGhvbXZpbGxhIiwiYSI6ImNrc2FqbHc2ZDE2ejgycHJtdHcxcTl3ZXIifQ.dFuDgxNvOenGAlUNpjVwVA';

/* export const pointerIconAmenitie = new L.Icon({
    iconUrl: iconAmenitiePath,
    iconRetinaUrl: iconAmenitiePath,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
    shadowUrl: null,
    shadowSize: [68, 95],
    shadowAnchor: [20, 92]
});

export const pointerIcon = new L.Icon({
    iconUrl: iconPath,
    iconRetinaUrl: iconPath,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
    iconSize: [25, 55],
    shadowUrl: null,
    shadowSize: [68, 95],
    shadowAnchor: [20, 92]
}); */

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class MapaLotes extends Component {

    constructor(props) {
        super(props)

        this.mapContainer = React.createRef();

        this.state = {

            isOn: true,
            loadingMap: false,

            zoom: 13,
            opacity: 0.75,

            editMode: 'none',
            latLngString: '',

            kml: null,
            mapita: null,

            modalGracias: false,
            modalRedireccion: false,
            modalDetalle: false,
            urlBooking: null,

            values: {
                name: null,
                surname: null,
                phone: null,
                email: null,
                entrepreneurshipid: 0,
                producttypeid: 0,
                entrepreneurshipname: '',
                producttypename: '',
                productid: null,
                message: null
            },
            isSubmitting: false,
            isError: false,
            message: null

        };

        this.toggleBar = this.toggleBar.bind(this);
    }

    toggleBar() {
        this.setState({
            showBar: !this.state.showBar
        });
    }

    toggleModal(idLote, emprendimiento, emprendimientoName, producto, productoName, codigoLote, bookingurl) {

        console.log("toggle modallllll")


        this.setState({
            loteSeleccionado: idLote,
            urlBooking: bookingurl,
            values: {
                name: null,
                surname: null,
                phone: null,
                email: null,
                entrepreneurshipid: emprendimiento,
                producttypeid: producto,
                productid: idLote,
                entrepreneurshipname: emprendimientoName,
                producttypename: productoName,
                message: this.props.txtMensaje ? this.props.txtMensaje.replace('{0}', codigoLote) : ''
            },
            modalDetalle: !this.state.modalDetalle
        });



        this.setState({ modalRedireccion: false });

        this.setState({ modalGracias: false });
    }

    toggleModalGracias() {
        this.setState({ modalGracias: !this.state.modalGracias });
        this.setState({ modalRedireccion: false });

        this.setState({ modalDetalle: false });

    }

    toggleModalRedireccion(url) {
        console.log("setamos url", url)
        this.setState({ modalRedireccion: !this.state.modalRedireccion, urlBooking: url });
        this.setState({ modalGracias: false });
        this.setState({ modalDetalle: false });
        console.log(this.state)
    }

    componentDidMount() {

        const { latCenter, lonCenter } = this.props; // zoom de ws viene mal
        const {  zoom } = this.state;

        var zoomToAssign = null;
        
        if (this.props.barrioNombre != 'Puertos'){
            zoomToAssign = zoom+2;
        }else{
            zoomToAssign = zoom;
        }

        let map = new mapboxgl.Map({
            container: this.mapContainer.current, 
            style: 'mapbox://styles/thomvilla/ckt4hz9c00iio17o8v0j0ztgd',
            center: [lonCenter, latCenter],
            zoom: zoomToAssign
        });

        //
        this.setState({ loadingMap: true });


        if (this.props.lotes) {

            this.props.lotes.map((val, i) => {

                const innerHtmlContent = ``;
                const divElement = document.createElement('div');
                const assignBtn = document.createElement('div');

                // <img style='width: 100%;' src='${val.pictureurl}'/>

                assignBtn.innerHTML = `<div><div class='contentMapa'><h3 class='headerMapa'>${val.text1}</h3><h1 class='precio'>${val.text2}</h1><h1 class='tipo_producto'>${val.text3}</h1><h3 class='lote'>${val.text4}</h3><h3 class='loteSub'>${val.text5}</h3></div><div class='grid-container-boton-marker'> 
                                        <button class='botonMapa' type='button' ">Contactar</button></div></div>`;

                divElement.innerHTML = innerHtmlContent;
                divElement.appendChild(assignBtn);

                assignBtn.addEventListener("click", (e) => { this.toggleModal(val.id, val.entrepreneurshipid, val.entrepreneurshipname, val.producttypeid, val.producttypename, val.code, val.bookingurl) }, false);

                const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(divElement);
                const el = document.createElement('div');
                el.id = 'marker';

                new mapboxgl.Marker({ color: "#ff4310", draggable: false }).setLngLat([val.longitude, val.latitude]).setPopup(popup).addTo(map);

            });

        }

        map.on('load', function () {

            var fixButton = document.getElementById('btn_mapa_container');

            fixButton.onclick = function () {
console.log('resize mapp')
                var mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
                var mapDiv = document.getElementById('map');

                mapCanvas.style.width = '700px';
                mapDiv.style.width = '100%';

                map.resize();
            }

        });




        this.setState({ loadingMap: false });

        /*  load from aws
            fetch("https://miiiisa-landing-consultatio.s3.us-east-2.amazonaws.com/RealState/Puertos/Puertos.kml")
            .then(res => res.text()).then(kmlText => {
                const parser = new DOMParser();
                const kmlXML = parser.parseFromString(kmlText, "text/xml");
                this.setState({
                            kml: xmlMapa
                        });
            }); 
        */

    }

    handleInputChange = e => {
        this.setState({
            values: { ...this.state.values, [e.target.name]: e.target.value }
        });
    }

    submitForm = async e => {

        e.preventDefault();

        this.setState({ isSubmitting: true });

        const res = await fetch(this.props.apiMailing, {
            method: "POST",
            body: JSON.stringify(this.state.values),
            headers: {
                "Content-Type": "application/json"
            }
        });

        this.setState({ isSubmitting: false });
        
        const data = await res.json();

        if (data.success == true) {
            this.setState({ message: data.result, modalGracias: true, modalDetalle: false, })
            setTimeout(() =>
                this.setState({
                    isError: false,
                    message: null,
                    modalDetalle: false,
                    modalGracias: true,
                    values: {
                        name: null,
                        surname: null,
                        phone: null,
                        email: null,
                        entrepreneurshipid: 0,
                        producttypeid: 0,
                        message: null
                    },
                }),
                1750
            );
        } else {
            this.setState({ message: data.message, isError: true });
            setTimeout(() =>
                this.setState({
                    isError: false,
                    message: null,

                }),
                2500
            );
        }


    };


    render() {

        //const position = [this.props.latCenter, this.props.lonCenter]

        return (
            <>
                <div style={{ height: '100%' }} ref={this.mapContainer} className="map-container" id='map'>
                    {
                        this.state.loadingMap ?
                            <ReactLoading type={'balls'} color="#fff" />
                            :
                            null
                    }
                </div>

                <Modal
                    isOpen={this.state.modalDetalle}
                    onRequestClose={() => this.toggleModal()}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="container">
                        <div className="row" style={{ backgroundColor: '#002337' }}>
                            <div className="col-lg-6 colBanner2" >
                                <h1 class='txtWhite titulocontacto'>Contactate con nosotros</h1>
                                <h5 className="footerContacto">info@puertos.com.ar | 0800-888-puertos</h5>
                            </div>
                            <div className="col-lg-6 formcontainer" >
                                <div className="contact-form">
                                    <form id="contact-form" onSubmit={this.submitForm}>
                                        <div className="row">
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="name"
                                                    id="name"
                                                    type="text"
                                                    placeholder="*Nombre"
                                                    value={this.state.values.name}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="surname"
                                                    id="surname"
                                                    type="text"
                                                    placeholder="*Apellido"
                                                    value={this.state.values.surname}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="phone"
                                                    id="phone"
                                                    type="text"
                                                    placeholder="*Telefono"
                                                    value={this.state.values.phone}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-6 no-gutterrr">
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="text"
                                                    placeholder="*Email"
                                                    value={this.state.values.email}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div className="col-md-6 col-12 selectdiv">
                                                <select name="entrepreneurshipid"
                                                    value={this.state.values.entrepreneurshipid}
                                                    onChange={this.handleInputChange}
                                                    className="selectcontact" placeholder="Barrio">
                                                    <option value={this.state.values.entrepreneurshipid}>{this.state.values.entrepreneurshipname}</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 col-12 selectdiv">
                                                <select name="producttypeid"
                                                    value={this.state.values.producttypeid}
                                                    onChange={this.handleInputChange}
                                                    className="selectcontact" placeholder="Tipo Producto">
                                                    <option value={this.state.values.producttypeid}>{this.state.values.producttypename}</option>
                                                </select>
                                            </div>
                                            <div className="col-12">
                                                <textarea name="message"
                                                    id="message"
                                                    type="text"
                                                    placeholder="*Mensaje"

                                                    onChange={this.handleInputChange} defaultValue={this.state.values.message} /></div>
                                            {
                                                this.state.isError ?
                                                    <p className="msjFooterE">
                                                        {this.state.message}
                                                    </p>
                                                    :
                                                    this.state.message ?
                                                        () => this.toggleModalGracias()
                                                        :
                                                        null
                                            }
                                            <button className='btnenviarContacot' type={'submit'}>{this.state.isSubmitting ? 'Enviando ...' : 'Enviar'}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.modalRedireccion}
                    onRequestClose={() => this.toggleModalRedireccion()}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="container contenedorFormRedir">
                        <div className="row">
                            <div className="col-3" style={{ backgroundColor: '#133447' }}></div>
                            <div className="col-6 colBanner2" style={{ backgroundColor: '#133447' }}>
                                <h5 class='txtWhite superTitleModal'>Aviso de redireccionamiento</h5>
                                <h1 class='txtWhite titulocontacto'>Continuá con tu reserva en nuestro Marketplace</h1>
                                <a href={this.state.urlBooking} target="_blank" className="btn_circular_nobg" >Continuar</a>
                            </div>
                            <div className="col-3" style={{ backgroundColor: '#133447' }}></div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={this.state.modalGracias}
                    onRequestClose={() => this.toggleModalGracias()}
                    style={customStyles}
                    contentLabel=""
                >
                    <div className="container contenedorFormRedir">
                        <div className="row">
                            <div className="col-3" style={{ backgroundColor: '#133447' }}></div>

                            <div className="col-12 colBanner2" style={{ backgroundColor: '#133447' }}>
                                <br />
                                <h5 class='txtWhite superTitleModal'>Mensaje enviado</h5>
                                <h1 class='txtWhite titulocontacto'>Gracias por contactarte con nosotros</h1>
                                <a onClick={() => this.toggleModalGracias()} className="btn_circular_nobg" >Continuar</a>
                                <br />
                            </div>
                            <div className="col-3" style={{ backgroundColor: '#133447' }}></div>
                        </div>
                    </div>
                </Modal>

            </>
        )
    }
}

export default MapaLotes;