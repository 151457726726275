import React, { Component, useCallback } from 'react';

import NavBar from './components/NavBar';
import Slider from './components/SliderCarrousel';
import SliderVideo from './components/SliderVideo';
import TabCiudad from './components/TabCiudad';
import TabLotes from './components/TabLotes';
import ImageBanner from './components/ImgBanner1';
import ImageBanner2 from './components/ImgBanner2';
import ImageBanner3 from './components/ImgBanner3';
import ContactoBanner from './components/ContactoBanner';
import PuertosUbicacion from './components/PuertosUbicacion';
import ResidenciasDelLago from './components/ResidenciasDelLago';

import Footer from './components/Footer';
import scrollToComponent from 'react-scroll-to-component';

//import ReactGA from 'react-ga';
//ReactGA.initialize('UA-177072652-1');
//ReactGA.pageview(window.location.pathname + window.location.search);

//const enviroment = 'https://rvgdg1p2a1.execute-api.us-east-2.amazonaws.com/Staging'
const enviroment = 'https://ewomyq439l.execute-api.us-east-2.amazonaws.com/prod'

// API'S
const apiDatosLanding = enviroment + '/api/sysparam?code=';
const apiProductType = enviroment + '/api/producttype/landing';
const apiBarrios = enviroment + '/api/Entrepreneurship/landing/PUERTOS';
const apiMailing = enviroment + '/api/Vendor/landing-ticket';

// API'S barrios sin CABECERA PUERTOS
//const apiBarrios =  enviroment + '/api/Entrepreneurship/children/PUERTOS';

class Home extends Component {

    constructor() {

        super()

        this.state = {
            isOpen: false,
            porcentaje: null,
            cuota: null,
            costo: null,
            tipoProd: null,
            barrios: null,
            numContacto: null,
            txtMensaje: null,
            kml: null,
            LandingInitialCostResidenciasLagoText: null,
            LandingTotalMonthlyPaymentsResidenciasLagoText: null,
            LandingInitialCostRiberasText: null,
            LandingTotalMonthlyPaymentsRiberasText: null,
            LandingInitialPercentBillOfSaleRiberasText: null,
            LandingInitialPercentBillOfSaleResidenciasLagoText: null,

            LandingInitialCostAmarrasText: null,
            LandingTotalMonthlyPaymentsAmarrasText: null,
            LandingInitialPercentBillOfSaleAmarrasText: null,
        }

    }

    componentDidMount() {
        scrollToComponent(this.Blue, { offset: 0, align: 'middle', duration: 500, ease: 'inCirc' });
    }

    componentWillMount() {


        this.fetchCosto()
        this.fetchPorcentaje()
        this.fetchCuota()
        this.fetchProductType()
        this.fetchBarrios()
        this.fetchNumTel()
        this.fetchTextoConsulta()
        this.fetchLandingInitialCostResidenciasLagoText()
        this.fetchLandingTotalMonthlyPaymentsResidenciasLagoText()
        this.getLandingInitialCostRiberasText()
        this.getLandingTotalMonthlyPaymentsRiberasText()
        this.getLandingInitialPercentBillOfSaleRiberasText()
        this.fetchLandingInitialPercentBillOfSaleResidenciasLagoText()

        this.fetchLandingInitialCostAmarrasText()
        this.fetchLandingTotalMonthlyPaymentsAmarrasText()
        this.fetchLandingInitialPercentBillOfSaleAmarrasText()

    
    }



    /*
    Puertos:
    LandingInitialPercentBillOfSaleText = 0%
    LandingTotalMonthlyPaymentsText = 12
    LandingInitialCostText = US$ 65.000

    Residencias del Lago:
    LandingInitialCostResidenciasLagoText = US$ 150.000
    LandingTotalMonthlyPaymentsResidenciasLagoText = 12
    LandingInitialPercentBillOfSaleResidenciasLagoText = 35%

    Riberas:
    LandingInitialCostRiberasText = US$ 70.000
    LandingTotalMonthlyPaymentsRiberasText = 36
    LandingInitialPercentBillOfSaleRiberasText = 35%

    Amarras:
    LandingInitialCostAmarrasText = US$ 90.000
    LandingTotalMonthlyPaymentsAmarrasText = 36
    LandingInitialPercentBillOfSaleAmarrasText = 35%

    */

    // amarras
    fetchLandingInitialCostAmarrasText() { fetch(apiDatosLanding + 'LandingInitialCostAmarrasText').then(response => response.json()).then(data => this.setState({ LandingInitialCostAmarrasText: data.value })); }
    fetchLandingTotalMonthlyPaymentsAmarrasText() { fetch(apiDatosLanding + 'LandingTotalMonthlyPaymentsAmarrasText').then(response => response.json()).then(data => this.setState({ LandingTotalMonthlyPaymentsAmarrasText: data.value })); }
    fetchLandingInitialPercentBillOfSaleAmarrasText() { fetch(apiDatosLanding + 'LandingInitialPercentBillOfSaleAmarrasText').then(response => response.json()).then(data => this.setState({ LandingInitialPercentBillOfSaleAmarrasText: data.value })); }
    //corregir esto
    fetchPorcentaje() { fetch(apiDatosLanding + 'LandingInitialCostAmarrasText').then(response => response.json()).then(data => this.setState({ LandingInitialCostAmarrasText: data.value })); }
    fetchCosto() { fetch(apiDatosLanding + 'LandingTotalMonthlyPaymentsAmarrasText').then(response => response.json()).then(data => this.setState({ LandingTotalMonthlyPaymentsAmarrasText: data.value })); }
    fetchCuota() { fetch(apiDatosLanding + 'LandingInitialPercentBillOfSaleAmarrasText').then(response => response.json()).then(data => this.setState({ LandingInitialPercentBillOfSaleAmarrasText: data.value })); }


    // residencias
    fetchLandingInitialCostResidenciasLagoText() { fetch(apiDatosLanding + 'LandingInitialCostResidenciasLagoText').then(response => response.json()).then(data => this.setState({ LandingInitialCostResidenciasLagoText: data.value })); }
    fetchLandingTotalMonthlyPaymentsResidenciasLagoText() { fetch(apiDatosLanding + 'LandingTotalMonthlyPaymentsResidenciasLagoText').then(response => response.json()).then(data => this.setState({ LandingTotalMonthlyPaymentsResidenciasLagoText: data.value })); }
    fetchLandingInitialPercentBillOfSaleResidenciasLagoText() { fetch(apiDatosLanding + 'LandingInitialPercentBillOfSaleResidenciasLagoText').then(response => response.json()).then(data => this.setState({ LandingInitialPercentBillOfSaleResidenciasLagoText: data.value })); }

    // vivi puertos
    //fetchPorcentaje() { fetch(apiDatosLanding + 'LandingInitialPercentBillOfSaleText').then(response => response.json()).then(data => this.setState({ porcentaje: data.value })); }
    //fetchCosto() { fetch(apiDatosLanding + 'LandingTotalMonthlyPaymentsText').then(response => response.json()).then(data => this.setState({ cuota: data.value })); }
    //fetchCuota() { fetch(apiDatosLanding + 'LandingInitialCostText').then(response => response.json()).then(data => this.setState({ costo: data.value })); }

    // ribera
    getLandingInitialCostRiberasText() { fetch(apiDatosLanding + 'LandingInitialCostRiberasText').then(response => response.json()).then(data => this.setState({ LandingInitialCostRiberasText: data.value })); }
    getLandingTotalMonthlyPaymentsRiberasText() { fetch(apiDatosLanding + 'LandingTotalMonthlyPaymentsRiberasText').then(response => response.json()).then(data => this.setState({ LandingTotalMonthlyPaymentsRiberasText: data.value })); }
    getLandingInitialPercentBillOfSaleRiberasText() { fetch(apiDatosLanding + 'LandingInitialPercentBillOfSaleRiberasText').then(response => response.json()).then(data => this.setState({ LandingInitialPercentBillOfSaleRiberasText: data.value })); }



    fetchTextoConsulta() {
        fetch(apiDatosLanding + 'LandingProductQuestionMessageText').then(response => response.json()).then(data => this.setState({ txtMensaje: data.value }));
    }

    fetchNumTel() {
        fetch(apiDatosLanding + 'LandingContactDestinationPhoneNumber').then(response => response.json()).then(data => this.setState({ numContacto: data.value }));
    }
    fetchProductType() {
        fetch(apiProductType).then(response => response.json()).then(data => this.setState({ tipoProd: data })).catch(function (err) { console.log('debug producType') })
    }

    fetchBarrios() {
        fetch(apiBarrios).then(response => response.json()).then(data => this.setState({ barrios: data })).catch(function (err) { console.log('debug fetchBarrios') })
    }



    render() {
        return (
            <div>
                <NavBar
                    clickMaster={() => scrollToComponent(this.MasterPlan, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickCiudad={() => scrollToComponent(this.Ciudad, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickUbicacion={() => scrollToComponent(this.Ubicacion, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickContacto={() => scrollToComponent(this.Contacto, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                />
                <Slider

                    porcentaje={this.state.porcentaje ? this.state.porcentaje : ''}
                    cuota={this.state.cuota ? this.state.cuota : ''}
                    costo={this.state.costo ? this.state.costo : ''}

                    LandingInitialCostResidenciasLagoText={this.state.LandingInitialCostResidenciasLagoText ? this.state.LandingInitialCostResidenciasLagoText : ''}
                    LandingTotalMonthlyPaymentsResidenciasLagoText={this.state.LandingTotalMonthlyPaymentsResidenciasLagoText ? this.state.LandingTotalMonthlyPaymentsResidenciasLagoText : ''}
                    LandingInitialPercentBillOfSaleResidenciasLagoText={this.state.LandingInitialPercentBillOfSaleResidenciasLagoText ? this.state.LandingInitialPercentBillOfSaleResidenciasLagoText : ''}

                    LandingInitialCostRiberasText={this.state.LandingInitialCostRiberasText ? this.state.LandingInitialCostRiberasText : ''}
                    LandingTotalMonthlyPaymentsRiberasText={this.state.LandingTotalMonthlyPaymentsRiberasText ? this.state.LandingTotalMonthlyPaymentsRiberasText : ''}
                    LandingInitialPercentBillOfSaleRiberasText={this.state.LandingInitialPercentBillOfSaleRiberasText ? this.state.LandingInitialPercentBillOfSaleRiberasText : ''}


                    LandingInitialCostAmarrasText={this.state.LandingInitialCostAmarrasText ? this.state.LandingInitialCostAmarrasText : ''}
                    LandingTotalMonthlyPaymentsAmarrasText={this.state.LandingTotalMonthlyPaymentsAmarrasText ? this.state.LandingTotalMonthlyPaymentsAmarrasText : ''}
                    LandingInitialPercentBillOfSaleAmarrasText={this.state.LandingInitialPercentBillOfSaleAmarrasText ? this.state.LandingInitialPercentBillOfSaleAmarrasText : ''}


                    clickMaster={() => scrollToComponent(this.MasterPlan, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickContacto={() => scrollToComponent(this.Contacto, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}

                />


                <SliderVideo />
                <TabCiudad
                    ref={(section) => { this.Ciudad = section; }}
                />
                <ImageBanner imgClass={'testimonial-slider-area-bg1'} />
                <TabLotes
                    dataLotes={this.state.barrios ? this.state.barrios : null}
                    api={enviroment}
                    productos={this.state.tipoProd ? this.state.tipoProd : null}
                    txtMensaje={this.state.txtMensaje ? this.state.txtMensaje : null}
                    apiMailing={apiMailing}
                    kml={this.state.kml ? this.state.kml : null}
                    ref={(section) => { this.MasterPlan = section; }}
                />
                <ResidenciasDelLago />
                <ImageBanner2 imgClass={'testimonial-slider-area-bg2'} />
                <PuertosUbicacion
                    ref={(section) => { this.Ubicacion = section; }}
                />

                <ImageBanner3 imgClass={'testimonial-slider-area-bg3'} />

                <ContactoBanner
                    apiMailing={apiMailing}
                    dataLotes={this.state.barrios ? this.state.barrios : null}
                    productos={this.state.tipoProd ? this.state.tipoProd : null}
                    ref={(section) => { this.Contacto = section; }}
                />

                <Footer numContacto={this.state.numContacto}
                    clickMaster={() => scrollToComponent(this.MasterPlan, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickCiudad={() => scrollToComponent(this.Ciudad, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickUbicacion={() => scrollToComponent(this.Ubicacion, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })}
                    clickContacto={() => scrollToComponent(this.Contacto, { offset: 0, align: 'top', duration: 500, ease: 'inCirc' })} />
            </div>
        )
    }
}

export default Home;