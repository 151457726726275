import React, { Component } from 'react';
import ReactLoading from "react-loading";
import Background from "../assets/img/Slide1.jpg"
import Background2 from "../assets/img/fondo2.jpg"
import Background3 from "../assets/img/Slide3.jpg"
import Background4 from "../assets/img/slide4.jpg"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


class Slider extends Component {

    render() {

        const indicatorStyles: CSSProperties = {
            background: '#fff',
            width: 10,
            height: 10,
            borderRadius: 20,
            display: 'inline-block',
            margin: '0 8px',
        };

        return (

            <div>
                <div className="service-grid-slider-area">
                    <Carousel
                        statusFormatter={(current, total) => ''}
                        autoPlay={true}
                        infiniteLoop={true}
                        renderIndicator={(onClickHandler, isSelected, index, label) => {
                            if (isSelected) {
                                return (<li style={{ ...indicatorStyles, background: '#ff5730' }} aria-label={`Selected: ${label} ${index + 1}`} title={`Selected: ${label} ${index + 1}`} />);
                            } else {
                                return (<li style={indicatorStyles} onClick={onClickHandler} onKeyDown={onClickHandler} value={index} key={index} role="button" tabIndex={0} title={`${label} ${index + 1}`} aria-label={`${label} ${index + 1}`} />);
                            }
                        }}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                ''
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                ''
                            )
                        }>




                        <div>
                            <div className="hero-alider-area">
                                <div className="swiper-slide" key="1">
                                    <div className="hero-slider__single-item" style={{backgroundImage: "url(" + Background + ")"}}>
                                        <div className="hero-slider__content-wrapper">
                                            <div className="container  contenedorSliderCarrousel">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="hero-slider__content">
                                                            <h3 className='head_slider'>AMARRAS ETAPA II</h3>
                                                            <h1 className='tituloSlider'>Venís</h1>
                                                            <h1 className='tituloSlider'>a navegar</h1>
                                                            <h4 className='footer_slider'></h4>
                                                            <a onClick={this.props.clickMaster} class="ht-btn ht-btn--default d-inline-block boldd botonSlider" style={{ fontSize: '16px', width: '109px', padding: '10px 15px', fontFamily: '"HelveticaNowText-Bold", sans-serif' }}>Ver Lotes</a>
                                                        </div>
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="hero-alider-area">
                                <div className="swiper-slide" key="1">
                                    <div className="hero-slider__single-item" style={{backgroundImage: "url(" + Background2 + ")"}}>
                                        <div className="hero-slider__content-wrapper">
                                            <div className="container  contenedorSliderCarrousel">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="hero-slider__content">
                                                            <h3 className='head_slider'>AMARRAS ETAPA II</h3>
                                                            <h1 className='tituloSlider'>Venís</h1>
                                                            <h1 className='tituloSlider'>a vivir</h1>
                                                            <h4 className='footer_slider'></h4>
                                                            <a onClick={this.props.clickMaster} class="ht-btn ht-btn--default d-inline-block boldd botonSlider" style={{ fontSize: '15px', width: '109px', padding: '10px 15px', fontFamily: '"HelveticaNowText-Bold", sans-serif' }}>Ver Lotes</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="hero-alider-area">
                                <div className="swiper-slide" key="1">
                                    <div className="hero-slider__single-item" style={{backgroundImage: "url(" + Background3 + ")"}}>
                                        <div className="hero-slider__content-wrapper">
                                            <div className="container  contenedorSliderCarrousel">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="hero-slider__content">
                                                            <h3 className='head_slider'>AMARRAS ETAPA II</h3>
                                                            <h1 className='tituloSlider'>Venís</h1>
                                                            <h1 className='tituloSlider'>a explorar</h1>
                                                            <h4 className='footer_slider'></h4>
                                                            <a onClick={this.props.clickMaster} class="ht-btn ht-btn--default d-inline-block boldd botonSlider" style={{ fontSize: '15px', width: '109px', padding: '10px 15px', fontFamily: '"HelveticaNowText-Bold", sans-serif' }}>Ver Lotes</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      

                        

                        



                    </Carousel>
                </div>
                <div className="col-lg-12 rowImportes" style={{transform:'translateY(50%) !important'}}>
                    <div className="container containerValores" style={{transform:'translateY(50%) !important'}}>
                        <div className="row ">
                            <div className="col-12 colBanner containerflexx" style={{ backgroundColor: '#012338' }}>
                                <div className="sliderSup conBorde">
                                    <h3 className='txtWhite subtitulo1' style={{ fontFamily: '"StandardStencil", sans-serif', textAlign: 'left' }}><strong className="orage">{this.props.LandingInitialCostAmarrasText ? this.props.LandingInitialCostAmarrasText : <ReactLoading type={'balls'} color="#fff" />}</strong></h3>
                                    <h1 className='txtWhite titulo onlyDesktop'>Valor base de Lotes</h1>
                                    <h1 className='txtWhite titulo onlyMobile'>Base Lotes</h1>
                                </div>

                                <div className="sliderSup conBorde">

                                    <h3 className='txtWhite subtitulo1' style={{ fontFamily: '"StandardStencil", sans-serif', textAlign: 'left' }}><strong className="">{this.props.LandingInitialPercentBillOfSaleRiberasText ? this.props.LandingInitialPercentBillOfSaleRiberasText : <ReactLoading type={'balls'} color="#fff" />}</strong></h3>
                                    <h1 className='txtWhite titulo'>al boleto</h1>
                                </div>

                                <div className="sliderSup">
                                    <h3 className='txtWhite subtitulo1' style={{ fontFamily: '"StandardStencil", sans-serif', textAlign: 'left' }}><strong className="">{this.props.LandingTotalMonthlyPaymentsRiberasText ? this.props.LandingTotalMonthlyPaymentsRiberasText : <ReactLoading type={'balls'} color="#fff" />}</strong></h3>
                                    <h1 className='txtWhite titulo'>cuotas</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}

export default Slider;