import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { FaBars } from 'react-icons/fa';

class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {

            showMenu: false,
            ciudad: false,
            master: false,
            contacto: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.mobileMenuElement = React.createRef();

    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    }

    handleScroll() {
        if (this.mount) {
            //     this.setState({ scroll: window.scrollY });
        }
    }

    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({ top: el.offsetTop, height: el.offsetHeight });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        this.state.scroll > this.state.top ?
            document.body.style.paddingTop = `${this.state.height - 1}px`
            :
            document.body.style.paddingTop = 0;
    }

    componentWillUnmount() {
        this.mount = false;
    }

    toggleMenu() {
        this.setState({ showMenu: !this.state.showMenu });
    }

    clickCiudadFull() {
        this.setState({
            ciudad: true,
            master: false,
            ubicacion: false,
        });
        this.props.clickCiudad()
    }
    clickMasterFull() {
        this.setState({
            ciudad: false,
            master: true,
            ubicacion: false,
        });
        this.props.clickMaster()
    }

    clickUbiFull() {
        this.setState({
            ciudad: false,
            master: false,
            ubicacion: true,
        });
        this.props.clickUbicacion()
    }

    render() {
        return (
            <div style={{ position: 'sticky', top: '0', zIndex: '999999' }}>
                <div className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}>
                    <div className="header-area">
                        <div className="header-navigation-area default-bg">
                            <div className="container Telefono">
                                <div className="row">
                                    <div className="col-10 col-md-2 cenntrado logomobileee">
                                        <Link to={`/`}>
                                            <img src="./assets/img/logo_puertos.svg" className="img-fluid logomovile" alt="Logo" />
                                        </Link>
                                    </div>
                                    {this.state.showMenu ?
                                        <>
                                            <div className="col-2 mobileMenuBtn">
                                                <nav style={{ paddingLeft: '10px' }}>
                                                    <Link onClick={() => this.toggleMenu()}><img src='./assets/img/cruz.svg' /></Link>
                                                </nav>
                                            </div>
                                            <div className="col-12  col-md-8 mobileMenu">
                                                <div className="header-navigation header-navigation--header-default position-relative">
                                                    <div className="header-navigation__nav position-static">
                                                        <nav>
                                                            <ul>
                                                                <li><Link onClick={() => this.props.clickCiudad()}>Ciudad</Link></li>
                                                                <li><Link onClick={() => this.props.clickMaster()}>Masterplan</Link></li>
                                                                <li><Link onClick={() => this.props.clickUbicacion()}>Ubicación</Link></li>
                                                                <li className="mobileMenuContactoLink mobileCotact"><Link onClick={() => this.props.clickContacto()}>Contacto</Link></li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="col-2 mobileMenuBtn">
                                            <nav>
                                                <Link onClick={() => this.toggleMenu()}><img src='./assets/img/hamburguesa.svg' /></Link>
                                            </nav>
                                        </div>
                                    }
                                    <div className="col-2" className="mobileMenuContacto">
                                        <div className="top-bar-right-wrapper">
                                            <Link onClick={() => this.clickContacto()} className="ht-btn ht-btn--default d-inline-block">Contacto</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/*para desktop*/}
                            <div className="container Desktop desktoppaddd">
                                <div className="row">
                                    <div className="col-10 col-md-2">
                                        <Link to={`/`}>
                                            <img src="./assets/img/logo.png" className="img-fluid logomovile" alt="Logo" style={{ width: '75%' }} />
                                        </Link>
                                    </div>

                                    <div className="col-12  col-md-8">
                                        <div className="header-navigation header-navigation--header-default position-relative">
                                            <div className="header-navigation__nav position-static">
                                                <nav>
                                                    <ul>
                                                        <li><Link onClick={() => this.props.clickCiudad()}>Ciudad</Link></li>
                                                        <li><Link onClick={() => this.props.clickMaster()}>Masterplan</Link></li>
                                                        <li><Link onClick={() => this.props.clickUbicacion()}>Ubicación</Link></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-2">
                                        <div className="top-bar-right-wrapper">
                                            <Link onClick={() => this.props.clickContacto()} className="ht-btn ht-btn--default d-inline-block boldd" style={{ fontSize: '15px' }}>Contacto</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


export default NavBar;